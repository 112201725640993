import React from 'react'
import { Link } from 'react-router-dom'
import { path } from '../../config'
import Footer from '../../components/Footer'

export default function Index() {
  return (
    <div>
      <HeadLine />
      <AboutDescription />
      {/* <Hero /> */}
      {/* <FAQ /> */}
      <Footer />
    </div>
  )
}


function HeadLine() {
  return (
    <div className='w-full h-[calc(100vh)] relative text-secondary'>
      <img src={require('../../assets/img/about.png')} alt='TravelImage' className='w-full h-full object-cover object-center' />
      <div className='px-4 w-full h-full bg-black/20 absolute top-0 left-0 pt-20 flex flex-col gap-10 items-center justify-center text-center'>
        <div className='max-w-[900px] max-h-[80%] flex flex-col items-center'>
          <h1 className='md:text-[70px] px-5 font-extrabold font-[Poppins-bold] leading-tight'>
            Welcome to the family
          </h1>
          <p className='text-xl sm:w-[80%]'>JourneyEasyTM, is a family of like-minded go-getters who aren't afraid to try something new whilst earning something too!</p>
        </div>
        <div>
          <Link to={path.site} className='btn1'>Join Now</Link>
        </div>
      </div>

    </div>
  )
}

function AboutDescription() {
  return (
    <div className='flex justify-center p-20'>
      <div className='max-w-[800px] flex flex-col gap-5'>
        <p>
          Welcome to JourneyEasyTM. We are here to make your life more simple, and your Journeys, much, much, more Easy (see what we did there?) Not everyone gets the opportunity to run their own business, travel the world or control their own budgets -- with JourneyEasy YOU CAN!
        </p>
        <p>
          JourneyEasy is an online and mobile accessible portal which hosts absolutely everything you could ever need which relates to travel! Buy tickets, hotel rooms, packages, holidays, book visa appointments and entertainment! And the best thing about it, you can pay how and when YOU WANT!
        </p>
        <p>
          We give you your own key to the world by building you a portal with pre-designed themes to pick from for FREE. You can choose to upgrade your themes for a small fee, or leave it as it is. As part of our family, you get; discounts, ‘first come first serve’ promotions & flash sales and exclusive luxury items-­‐ and yes that’s FREE too!
        </p>

        <p>
          We are a happy family here, we pick our own work hours (usually after the 9-­5 grind or when we’ve put the kids to bed), choose our own clients and fill our own pockets with profit made the honest and (Journey)Easy way!
        </p>
        <p>
          There is always a member of the JourneyEasyTM family waiting to help out if we get stuck, even in the middle of the night someone will answer the phone just to chat or click on the happy face Icon on your portal screen to chat with us live, anytime anywhere!
        </p>
      </div>
    </div>
  )
}

function Hero() {
  return (
    <div className=''>
      <div className='bg-[#EEF8FC] flex justify-center p-10'>
        <div className='max-w-[800px] flex flex-col gap-6'>
          <h1 className='capitalize text-center text-[#262626] py-2'>A sub agent is a superstar</h1>
          <p>
            JourneyEasy is here to help you make money -- and have a little fun whilst you are out it.
          </p>
          <p>
            The formula is (Journey)Easy, we give you a whole list of products and tickets that you can use yourself or sell and tell you exactly how much they are, you can then decide how much you want to add to the price before you pass it on.
          </p>
          <p>
            In some special cases, there are products and tickets that you can earn Bonus points and credit for selling.
          </p>
          <p>
            Count Your Blessings-- Start with us by making a deposit on your profile.
            You can pay via our online link, into our bank account or by using the Interswitch WEBPAY system. The exact amount you pay will pop up on your portal. Every time you purchase something the balance on your portal is updated.
          </p>      
        </div>
      </div>
      <div className='flex justify-center p-10'>
        <div className='max-w-[800px] flex flex-col gap-6'>
          <h1 className='capitalize text-center text-[#262626] py-2'>A sub agent is a superstar</h1>
          <p>
            It really is (Journey) EasyPeasy to jump on board! Once we’ve done the
            “handshake” and you’ve filled out your profile questionnaire, send us your logo (or picture of your lovely face #JourneyEasySelfie)then we will build your profile just as you ask us to, set up your stationary so your tickets and vouchers look just how you want them to and then release you into the world!
          </p>      

          <p>
            Deposit as little or as much as you want into your account and start selling from day one, minute one! The little countdown on your login-­‐ screen tells you what your points balance is and if you have enough to pick the package, tour or holiday that you want.
          </p>      

          <p>
            Your portal is clever if you have a working bank card, you can book all you want without depositing a dime and then pay on checkout with your card or by clicking on the payment link and entering your username.
          </p>      

          <p>
            You will literally have the POWER to touch anywhere in the world. Your
            little portal will belong to you and only you and with it you can see all around the world.
          </p>      
        </div>
      </div>
      <div className='bg-[#EEF8FC] flex justify-center p-10'>
        <div className='max-w-[800px] flex flex-col gap-6'>
          <h1 className='capitalize text-center text-[#262626] py-2'>Money Matters</h1>
          <p>
            JourneyEasy is here to help you make money -- and have a little fun whilst you are out it.
          </p>      
          <p>
            The formula is (Journey)Easy, we give you a whole list of products and tickets that you can use yourself or sell and tell you exactly how much they are, you can then decide how much you want to add to the price before you pass it on.
          <p>
          </p>
            In some special cases, there are products and tickets that you can earn Bonus points and credit for selling.
          </p>
          <p>
            Count Your Blessings-- Start with us by making a deposit on your profile.
            You can pay via our online link, into our bank account or by using the Interswitch WEBPAY system. The exact amount you pay will pop up on your portal. Every time you purchase something the balance on your portal is updated.
          </p>      
        </div>
      </div>
      <div className=' flex justify-center p-10'>
        <div className='max-w-[800px] flex flex-col gap-6'>
          <h1 className='capitalize text-center text-[#262626] py-2'>Earn bonus points on all deposits over N50,000</h1>
          <p>
            <b>Pay As You Go</b> -  With no deposit on your account, a paystack payment screen will pop up either on checkout or at the point of confirming a reservation / ticket. Payment will need to be made before you can progress any further. Payments for services may be made on the behalf of other travellers.
          </p>
          <p>
           <b>Bonus Points</b> -  whilst these can be earned and spent via the portal, you may not exchange them for legal tender or cash. If your portal is disabled from disuse, misuse or abuse any accrued bonus points will be deleted. Bonus points may only be collected on one portal and by one profile at a time and are not transferable between friends, family or referees.
          </p>
          <p>
            <b>Spare Change</b> - Any left over money from a purchase is added to your account on the portal and is visible as part of your deposit amount every time you log in. Personal account details are not stored, so each time you wish to clear your deposit account or refund your left over money an email must be sent to MoneyMatters@JourneyEasy.com with your user name and account details. If you ever need to shut down an account and clear your balance this should be sent in writing to the same email address.
          </p>
        </div>
      </div>
      <div className='bg-[#EEF8FC] flex justify-center p-10'>
        <div className='max-w-[800px] flex flex-col gap-6'>
          <h1 className='capitalize text-center text-[#262626] py-2'>Easy Money - Easy Bonus - Easy Points</h1>
          <p>
            <b>Money, Money, Money, Money!</b> - Was a hit song by Abba in the 70’s, now even though the song is about a woman spending more than she’s got and ended up looking for a sugar daddy, we’re here hoping that you don’t need a backup plan once you see what we’ve got to support you.
          </p>      

          <p>
            <b>Bonus Points</b> are as good as cash on your portal. You can collect points
            and spend them on your portal, adding them up to buy any of the
            JourneyEasy content
          </p>      


          <p>
            <b>“Special Offer” products</b> - sometimes, even the family at JourneyEasyTM get cancellations or we simply have too much! So for a limited period of time we will have special content, inventory or tickets for you to sell before a deadline hits. After the deadline the “Special Case” will disappear from your portal. Each “Special Offer” will tell you how many points you can earn. If you have sold any before the deadline, your home screen will display all the bonus points you’ve earned!
          </p>      

          <p>
            <b>Introduce a friend</b> - For each friend you introduce to JourneyEasyTM that joins the family and opens up their own portal, you earn points! If your friend hits the JourneyEasy sales target both of you get more points!
          </p>

          <p>
            Welcome to JourneyEasyTM. We are here to make your life more simple. And your Journey, much, much, more Easy
          </p>      
        </div>
      </div>
  </div>
  )
}

function FAQ() {
  return (
    <div className=' flex justify-center p-10'>
      <div className='max-w-[800px] flex flex-col gap-6'>
        <h1 className='capitalize text-center text-[#262626] py-2'>FAQ’S – You don’t have to know it all</h1>
        <p>
          You ask - we answer, it really is as (Journey)Easy as that. You have lots of questions so we’ve put more answers up on the FAQ’s on the site; in the meantime here are the most popular ones:
        </p>      

        <p>
          <b>What is a JourneyEasyTM Nomad?</b>
          Some of us get cold feet before we take the plunge into a deep and meaningful relationship… and sometimes some of us just don’t want to show our hand just yet-­‐ we have you covered, no need to sign up, get online and control the world through your own portal you can always quickly walk into any of our offices, use one of our free terminals to make your bookings and build packages. Pay online or bring the teller with you and email the whole thing to yourself/ your client or print and go.
        </p>      

        <p>
          <b>What happens if the friend I introduce sells more than I do?</b>
          Well then Lucky YOU! The more they sell, the more YOU get! Just make sure you have some activity on your own portal every 3 months to keep it active and you can have your friends and family padding your pocket whilst you sit back and relax.
        </p>      

        <p>
          <b>Can I have more than one portal and account?</b>
          Sure! If you think you can handle it! But all your contact details must be different and don’t forget, after 3 months of inactivity the account will be closed and any bonus points earned will disappear!
        </p>      
      </div>
    </div>
  )
}
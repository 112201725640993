import React from 'react'
import { Link } from 'react-router-dom'
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import { path } from '../config';

export default function Footer() {
  return (
    <div className='p-10 mt-10 flex gap-5 flex-wrap'>
      <div className='p-10 py-5'>
        <h1 className='text-gray-900'>JourneyEasy</h1>
      </div>
      <div className='flex flex-1 flex-wrap gap-5 min-w-[150px]'>
        <div className='flex-1'>
          <h3 className='text-gray-400 uppercase pb-6'>Company</h3>
          <div className='flex flex-col gap-2 text-sm'>
            <Link to="#">Terms & Conditions</Link>
            <Link to="#">Terms of use</Link>
            <Link to="#">Privacy Policy</Link>
          </div>
        </div>
        <div className='flex-1'>
          <h3 className='text-gray-400 uppercase pb-6'>Platform</h3>
          <div className='flex flex-col gap-2 text-sm'>
            <Link to="/about">About</Link>
            <Link to="#">FAQ</Link>
          </div>
        </div>
        <div className='flex-1'>
          <h3 className='text-gray-400 uppercase pb-6'>Help & Suuport</h3>
          <div className='flex flex-col gap-2 text-sm'>
            <Link to={`${path.site}/`}>Sign in / Register</Link>
          </div>
        </div>
        <div className='flex-1'>
          <h3 className='text-gray-400 uppercase pb-6'>Social</h3>
          <div className='flex gap-2 text-sm'>
            <Link target='_blank' to="https://www.facebook.com/profile.php?id=100054440532729&mibextid=JRoKGi" className='w-6 h-6 bg-[#2CC5FF] rounded-full flex items-center justify-center text-white p-2'>
              <FaFacebookF />
            </Link>
            <Link target='_blank' to="https://twitter.com/btmjourneyeasy_" className='w-6 h-6 bg-[#2CC5FF] rounded-full flex items-center justify-center text-white p-[6px]'>
              <FaTwitter />
            </Link>
            <Link target='_blank' to="https://www.instagram.com/journeyeasy_?igsh=MWxuaHptcG90bHdieg==" className='w-6 h-6 bg-[#2CC5FF] rounded-full flex items-center justify-center text-white p-[6px]'>
              <FaInstagram />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { path } from '../config';

export default function Header() {
  const [menu,setMenu] = useState(false);

  return (
    <div className='absolute z-50 w-full flex justify-between gap-4 text-secondary p-2 items-center'>
      <div className=''>
        <img src={require('../assets/img/logo.png')} alt='Journey Easy' className='w-[130px]' />
      </div>
      <div className='hidden md:flex gap-4 justify-between items-center flex-1'>
        <div className='flex gap-16 flex-1 justify-center'>
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <a href='http://blog.journeyeasy.net' target='_blank'>News</a>
          {/* <Link to="/">Contact</Link> */}
        </div>
        <div className='flex gap-4 px-4 justify-end items-center'>
          <Link to={`${path.site}`}>Login</Link>
          <Link to={`${path.site}?view=signup`} className='btn1'>Signup</Link>
        </div>
      </div>
      <div className="md:hidden flex flex-col items-center px-2 justify-center relative cursor-pointer"
          onClick={() => setMenu(!menu)}
        >
          <div className={"hamburger "+(menu ? 'active fixed right-4':'')}></div>
        </div>
      <div className={" px-16 py-10 fixed top-0 bg-primary/80 right-0 w-full flex md:hidden backdrop-blur-[3px] "+(menu ? 'flex':'hidden')}>
        <div className="flex-1 flex flex-wrap items-end justify-end w-full gap-5 filter ">
          <div className='flex flex-wrap flex-col xs:flex-row  items-end gap-7 sm:gap-16 flex-1 justify-end py-4'>
            <Link to="/" className=''>Home</Link>
            <Link to="/about" className=''>About</Link>
            <a href='http://blog.journeyeasy.net' target='_blank'>News</a>
            {/* <Link to="/news" className=''>News</Link> */}
            {/* <Link to="/" className=''>Contact</Link> */}
          </div>
          <div className='flex gap-4 w-full justify-end items-center'>
            <Link to={`${path.site}`}>Login</Link>
            <Link to={`${path.site}?view=signup`} className='btn1 light'>Signup</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react';
import Footer from '../../components/Footer';
import NewsBox from '../../components/NewsBox';

export default function Index() {
  return (
    <div className=''>
      <div className='fixed w-screen h-screen top-0 left-0 -z-10'>
        <img src={require('../../assets/img/landingImage.png')} alt='TravelImage' className='w-full h-full object-cover object-top' />
      </div>
      <TipsNews />
      <div className='bg-secondary border'>
        <Footer />
      </div>
    </div>
  )
}

function TipsNews() {
  let datas = [...Array(5)];
  return (
    <div className='flex flex-wrap gap-4 mt-20'>

      <h1 className='w-full text-center text-white text-[45px] pt-10'>News</h1>
      <div className='bg-[#EEF8FC] p-10 mt-10 w-full flex flex-wrap gap-4'>
        {datas.map((data,i) => (
          <NewsBox data={data} key={i} />
        ))}
      </div>
    </div>
  )
}

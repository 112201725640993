import { Outlet } from 'react-router-dom';
import Header from './Header';

export function Navbar() {
  return (
    <div className='relative'>
      <Header />
      <div className='overflow-hidden'>
        {<Outlet />}
      </div>
    </div>
  )
}
import React from 'react'
import { Link } from 'react-router-dom'

export default function NewsBox({data}) {
  return (
    <Link to="/news/Travel Tips & News" className='flex flex-col flex-1 max-w-full min-w-[200px] sm:max-w-[300px] transition-all hover:scale-105 hover:shadow-lg shadow-primary'>
      <div>
        <div className='bg-primary/20 h-[250px]'></div>
      </div>
      <div className='py-4 px-2'>
        <h3 className='text-gray-400'>Travel Tips & News</h3>
        <p>Tips, news and entertainment from our travel experts to make your next trip even better.</p>
      </div>
    </Link>
  )
}

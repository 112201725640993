import React from 'react'
import Footer from '../../components/Footer';

export default function Display() {
  return (
    <div className=''>
      <div className='fixed w-screen h-screen top-0 left-0 -z-10'>
        <img src={require('../../assets/img/about.png')} alt='TravelImage' className='w-full h-full object-cover object-top' />
      </div>

      <div className='flex flex-wrap gap-4 mt-20'>

        <div className='w-full text-center text-white flex flex-col gap-14 py-10'>
          <h1 className='text-[45px] pt-10'>Travel Tips & News</h1>
          <p>Short Desciption</p>
        </div>
        <div className='bg-[#EEF8FC] p-10 mt-10 w-full flex flex-col items-center gap-4 '>
          <div className='flex flex-col gap-4 max-w-[1000px]'>
            <p>
              Welcome to JourneyEasyTM. We are here to make your life more simple, and your Journeys, much, much, more Easy (see what we did there?) Not everyone gets the opportunity to run their own business, travel the world or control their own budgets -- with JourneyEasy YOU CAN!
            </p>

            <p>
              JourneyEasy is an online and mobile accessible portal which hosts absolutely everything you could ever need which relates to travel! Buy tickets, hotel rooms, packages, holidays, book visa appointments and entertainment! And the best thing about it, you can pay how and when YOU WANT!
            </p>

            <p>
              We give you your own key to the world by building you a portal with pre-designed themes to pick from for FREE. You can choose to upgrade your themes for a small fee, or leave it as it is. As part of our family, you get; discounts, ‘first come first serve’ promotions & flash sales and exclusive luxury items-­‐ and yes that’s FREE too!
            </p>

            <p>
              We are a happy family here, we pick our own work hours (usually after the 9-­5 grind or when we’ve put the kids to bed), choose our own clients and fill our own pockets with profit made the honest and (Journey)Easy way!
            </p>

            <p>
              There is always a member of the JourneyEasyTM family waiting to help out if we get stuck, even in the middle of the night someone will answer the phone just to chat or click on the happy face Icon on your portal screen to chat with us live, anytime anywhere!
            </p>
          </div>
        </div>
      </div>

      <div className='bg-secondary border'>
        <Footer />
      </div>
    </div>
  )
}
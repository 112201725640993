import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { Navbar } from './components/Navbar';
import Index from './pages/LandingPage/Index';
import News from './pages/News/Index';
import About from './pages/About/Index';
import Display from './pages/News/Display';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route index element={<Index />} />
            <Route path='about' element={<About />} />
            <Route path='news' element={<News />} />
            <Route path='news/:id' element={<Display />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;

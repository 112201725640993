import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import { path } from '../../config';
import NewsBox from '../../components/NewsBox';

export default function Index() {
  return (
    <div className=''>
      <HeadLine />
      {/* <Hero /> */}
      {/* <TipsNews /> */}
      <Footer />
    </div>
  )
}

function TipsNews() {
  let datas = [...Array(5)];
  return (
    <div className='p-10 flex flex-wrap gap-4 '>
      {datas.map((data,i) => (
        <NewsBox data={data} key={i} />
      ))}
    </div>
  )
}

function Hero() {
  return (
    <div className='flex flex-col items-center p-10 min-h-screen justify-stretch'>
      <div className='flex flex-wrap-reverse justify-center gap-10 md:max-w-[70%] flex-1'>
        <div className='flex-1 rounded-2xl overflow-hidden min-w-[40%]'>
          <img src={require('../../assets/img/JourneyEasy Club.jpg')} alt='Hero' className='h-full object-cover object-right' />
        </div>
        <div className='flex flex-1 flex-col gap-5 self-center min-w-[250px]'>
          <h1>Sell Travel experiences Whilst you earn</h1>
          <p className=''>
           JourneyEasyTM, is a family of like-minded go-getters who aren't afraid to try something new whilst earning something too!
           We are adventurers, negotiators, expert enjoyment-masters and simple people who, appreciate the beautiful things this planet has to offer.
         </p>
         <Link to={path.site} className='btn1 self-start'>Join our family</Link>
        </div>
     </div>
  </div>
  )
}

function HeadLine() {
  return (
    <div className='w-full h-[calc(100vh)] relative text-secondary'>
    <img src={require('../../assets/img/Journeyeasylandingbg.jpg')} alt='TravelImage' className='w-full h-full object-cover object-center' />
    <div className='px-4 w-full h-full bg-black/50 absolute top-0 left-0 pt-20 flex flex-col gap-10 items-center justify-center text-center'>
      <div className='max-w-[1000px] max-h-[80%]'>
        <h1 className='md:text-[90px] px-5 font-extrabold font-[Poppins-bold] leading-tight uppercase'>
          Fuelled By Passion
        </h1>
        <h1>JOURNEYEASY, THE BTM TRAVEL CLUB</h1>
      </div>
      {/* <p>JourneyEasyTM, is a family of like-minded go-getters who aren't afraid to try something new whilst earning something too!</p> */}
      <div>
        <Link to="/about" className='btn1'>Learn More</Link>
      </div>
    </div>
  </div>
  )
}